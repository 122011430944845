.FooterSection {
  margin-top: 30px;
}

.FooterContainer {
  padding-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}

.FooterItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 10px;
}
